<template lang="pug">
  .card-body
    b-modal(:visible='ticketBooked' hide-footer hide-header centered)
      h5 Ticket Details
      div(v-if='bookingDetails')
        .row.px-4(v-for="bookingDetail, key in bookingDetails" :key="key")
          .col-6 {{key}}: 
          .col-6 {{bookingDetail}}
    .d-flex.justify-content-between
      h4 {{meta.from}} to {{meta.to}}
      b {{meta.departureDate.split("T")[0]}}
    .div.mt-3
      div(v-if='!tripSelected')
        .p-2.border-bottom(v-for='trip in trips' :key='trip.id' )
          b {{trip.operator}}
          .row
            .col-md-6
              bus-layout(:seats='trip.seatLayout' :loading='loading' :columns='trip.noOfColumn' :onContinue='tripConfirmed' :trip='trip')
            .col-md-6.bg-light.p-2
              .row
                .col-4 Amenities:
                .col-8 {{trip.amenities.toString()}}
              .row
                .col-4 Cost:
                b.col-8 Rs. {{trip.ticketPrice}}
              .row
                .col-4 Departure Time:
                .col-8 {{trip.departureTime}}
              .row
                .col-4 Bus Type:
                .col-8 {{trip.busType}}
              .row
                .col-4 Journey:
                .col-8 {{trip.journeyHour}} Hours
      .p-2.trip(v-else)
        div(v-if='paymentModal')
          pin-confirm(:previewDetails='previewDetails' :pinVerification='verifyPin')
        form(@submit.prevent='confirmPayment')
          .row
            .col-12.font-weight-bold Seats have been reserved. Confirm Booking now.
          .row
            .col-md-6.col-12
              .form-group
                label Operator
                input.form-control(type='text' v-model='selectedTrip.trip.operator' disabled)
              .form-group
                label Seats
                input.form-control(type='text' v-model='selectedTrip.seat' disabled)
              .form-group
                label Ticket Serial Number
                input.form-control(type='text' v-model='selectedTrip.ticketSrlNo' disabled)
            .col-md-6.col-12
              .form-group
                label Boarding Points
                b-select(v-model='selectedTrip.boardingPoint' :options='selectedTrip.boardingPoints')
              .form-group
                label Name*
                input.form-control(type='text' v-model='selectedTrip.name' required)
              .form-group
                label Contact Number*
                input.form-control(type='number' v-model='selectedTrip.contactNumber' required)
              .form-group
                label Email*
                input.form-control(type='email' v-model='selectedTrip.email' required)
              .form-group
                .show-message(v-if="message") {{message}}
                .spinner-border.spinner-border-sm.text-center(v-if='loading')
                show-bank(:bankClicked="confirmbank" :banks="banks" v-else)
</template>

<script>
import BusLayout from "./BusLayout";
import PinConfirm from "@/components/PinConfirm.vue";
import ShowBank from "@/components/ShowBank";
import api from "@/helpers/api";
import axios from "@/axios"
import payment from "@/mixins/payment";
export default {
  props: ["trips", "meta"],
  components: { BusLayout, ShowBank, PinConfirm },
  computed: {
    previewDetails() {
      return {
        "Seats Selected": this.selectedTrip.seat.toString(),
        "Total Amount to Pay": "Rs. " + this.selectedTrip.amount
      };
    }
  },
  mixins: [payment],
  data() {
    return {
      tripSelected: false,
      selectedTrip: {
        seat: [],
        trip: {},
        amount: 0,
        boardingPoints: [],
        boardingPoint: "",
        ticketSrlNo: ""
      },
      ticketBooked: false,
      bookingDetails: [],
      message: "",
      banks: [],
      loading: false
    };
  },
  methods: {
    confirmbank(bank) {
      this.selectedTrip.bank = bank;
    },
    verifyPin(verified) {
      this.pinVerification(this.confirmPayment, verified);
    },
    async tripConfirmed(seat, trip) {
      let failed = true;
      this.loading = true;
      try {
        this.selectedTrip.seat = seat;
        this.selectedTrip.trip = trip;
        this.selectedTrip.tripsId = trip.id;
        this.selectedTrip.amount = trip.ticketPrice * seat.length;
        this.selectedTrip.inputType = trip.inputTypeCode;
        const res = await axios.post(
          "services/bussewa/booking",
          this.selectedTrip
        );
        if (res.data.code === 1) {
          this.selectedTrip.ticketSrlNo = res.data.data.ticketSrlNo;
          this.selectedTrip.boardingPoints = res.data.data.boardingPoints;
          failed = false;
        }
      } catch (er) {
        failed = true;
      }
      if (failed) location.reload();
      else this.tripSelected = true;
      this.loading = false;
    },
    async confirmPayment() {
      this.loading = true;
      if (this.pinVerified) {
        const res = await axios.post(
          "services/bussewa/booking/passengerInfo",
          this.selectedTrip
        );
        if (res.data && res.data.code === 1) {
          const paymentResult = await axios.post(
            "services/bussewa/payment",
            this.selectedTrip
          );
          if (paymentResult.data && paymentResult.data.code === 1) {
            this.$store.dispatch("UPDATE_BALANCE");
            this.ticketBooked = true;
            const ticketQuery = await axios.post(
              "services/bussewa/booking/ticketQuery",
              this.selectedTrip
            );

            if (ticketQuery.data && ticketQuery.data.code === 1) {
              this.bookingDetails = ticketQuery.data.data;
              this.tripSelected = false;
            }
          } else this.message = paymentResult.data.data.message;
        }
      } else {
        this.paymentModal = true;
      }
      this.loading = false;
    }
  },
  async created() {
    this.selectedTrip.servicesId = await await api.serviceIdBySlug("BUS_SEWA");
    this.banks = await api.banks();
    this.loading = false;
  }
};
</script>