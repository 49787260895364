<template lang="pug">
  .container
    .layout
      .bus.bg-light.rounded
        .seat-section
          .flex-row.small.mt-2
            .flex-column(v-for='x in Math.ceil(seats.length/columns)' :key='x')
              .seat-cover.m-1(v-for='seat, key in getFormattedSeats(x)' :key='key') 
                .seat(@click='selectSeat(seat)'   
                      v-if='seat.displayName !== "na"' 
                      :class='{"brightness-low": seat.bookingStatus=== "Yes", "seat-selected": selectedSeats.includes(seat.displayName)}'
                      ) {{seat.displayName}}
                .driver(v-else-if='x === 1 && key === 0')
                  i.fas.fa-life-ring.fa-spin.font-lg
                .seat.no-seat(v-else) &nbsp;&nbsp;&nbsp;&nbsp;
      transition(name='fade' mode='in-out')
        .payment(v-if='selectedSeats.length > 0').mt-1
          .seats Selected Seats: {{selectedSeats.toString()}} ({{selectedSeats.length}})
          button.btn.btn-sm.btn-primary.mt-1(@click='tripSelect' :disabled='loading') Reserve
          .spinner-border.spinner-border-sm.mx-2(v-if="loading")
</template>

<script>
export default {
  methods: {
    getFormattedSeats(x) {
      return this.seats.slice((x - 1) * 5, 5 * x).reverse();
    },
    selectSeat(seat) {
      if (seat.bookingStatus === "No") {
        if (this.selectedSeats.includes(seat.displayName)) {
          this.selectedSeats = this.selectedSeats.filter(
            s => s != seat.displayName
          );
        } else {
          this.selectedSeats.push(seat.displayName);
        }
      }
    },
    tripSelect(){
      this.onContinue(this.selectedSeats, this.trip)
    }
  },
  props: ["seats", "columns", "onContinue", "trip", "loading"],
  data() {
    return {
      selectedSeats: []
    };
  },
  mounted() {}
};
</script>
<style scoped>
.font-lg {
  font-size: 20px;
  margin-top: 5px;
  margin-left: 4px;
}
.brightness-low {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.seat-selected {
  background: gray;
}
.seat-section {
  padding: 1px;
}
.no-seat {
  background: transparent !important;
  cursor: not-allowed !important;
  border: 0px !important;
}
.seat {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 20%;
  border: 1px solid black;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding: 0px 4px;
  margin: 1px 4px;
  text-align:center;
  color: black;
}
.flex-row {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .seat {
    padding-right: 4px;
  }
  .no-seat {
    padding: 0px !important;
    margin: 0 !important;
  }
}
</style>