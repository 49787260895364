<template lang="pug">
	.container
		.card
			transition(mode='out-in' name='fade')
				bus-trip(v-if='trips.length != 0' :trips='trips' :meta='searchRoute')
				.card-body.m-2(v-else)
					h2 Bus Sewa
					.text-right
						router-link(to="/bus-sewa/tickets") My Tickets
					form(@submit.prevent='searchTrip')
						.row.mt-4
							.col-lg-5.col-md-12
									div
										b From *
										b-select(autofocus :options='places' v-model='searchRoute.from' :disabled='loading' required)
									div.mt-4
										b To *	
										b-select(:options='returnPlaces()' v-model='searchRoute.to' :disabled='searchRoute.from === ""' required)
									div.mt-4
										b Shift *
										b-select(:options='availableShifts' v-model='searchRoute.shift' required)
							.col-lg-4.offset-lg-2.col-md-12
								b Date *
								date-pick( v-model='searchRoute.departureDate' :hasInputElement="false" :isDateDisabled='pastDate' required)
						.mt-2.show-message.mr-5(v-if='message') {{message}}
						button.my-2.btn.btn-primary(:disabled='loading') Search Trip
						.spinner-border.spinner-border-sm.mx-2(v-if='loading' role='status')
							.sr-only Loading...
</template>

<script>
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import BusTrip from "@/pages/bus/BusTrip";
import axios from "@/axios"
export default {
	components: { DatePick, BusTrip },
	methods: {
		returnPlaces() {
			return this.places.filter(place => place != this.searchRoute.from).sort();
		},
		pastDate(date) {
			return date < new Date().setDate(new Date().getDate() - 1);
		},
		async searchTrip() {
			if (this.searchRoute.departureDate != "") {
				this.loading = true;
				const res = await axios.post(
					"services/bussewa/trips",
					this.searchRoute
				);
				this.message = res.data.data.message;
				if (res.data && res.data.data && res.data.data.trips) {
					this.trips = res.data.data.trips;
				}
				this.loading = false;
			} else {
				this.message = "Select Date First";
			}
		}
	},
	data() {
		return {
			message: "",
			places: [],
			trips: [],
			searchRoute: {
				from: "",
				to: "",
				departureDate: new Date().toISOString(),
				shift: "both"
			},
			loading: true,
			availableShifts: ["day", "night", "both"]
		};
	},
	mounted() {
		axios
			.get("services/bussewa/routes")
			.then(r => {
				if (r.data && r.data.data) {
					this.places = r.data.data.routes.sort();
					this.loading = false;
				}
			})
			.catch(er => {
				this.message = "Could not fetch destinations. Service is down.";
				this.loading = false;
			});
	}
};
</script>